.hero {
  visibility: hidden;
  .container {
    .hero-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70vh;

      .slider {
        width: 60%;
        height: 110vh;
        z-index: -1;
      }
      .hero-content {
        position: relative;
        width: 50%;
        left: 100px;
        .hero-content-inner {
          width: 600px;
          margin: 0 auto;

          h1 {
            font-weight: 500;
            font-size: 32px;
            margin-bottom: 10px;
            .hero-content-line {
              margin: 0;
              height: 44px;
              overflow: hidden;
            }
          }
          p {
            font-size: 14px;
            line-height: 24px;
            font-weight: 300;
            padding-right: 48px;
            margin-bottom: 56px;
          }
          .btn-row {
            position: relative;

            button {
              background-color: rgb(252, 252, 252);
              border: none;
              font-size: 12px;
              padding: 0;
              cursor: pointer;
              text-transform: uppercase;
              font-weight: 700;
              letter-spacing: 0.7px;
              display: flex;
              align-items: center;
              &:focus {
                outline: none;
              }
              .arrow-icon {
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
                background: rgb(223, 221, 221);
                height: 42px;
                width: 42px;
                border-radius: 100px;
                left: 80px;
              }
            }
          }
        }
      }
    }
  }

  .hero-skills-inner {
    display: flex;
    position: relative;
    flex-direction: column;
    top: 40px;
    left: 200px;
    width: 400px;
  }
  .hero-skills-inner h1 {
    font-weight: 300;
    margin-bottom: 20px;
  }
  .hero-icon {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
  }
  .hero-icon img {
    width: 10%;
  }
  .hero-icon p {
    width: 30%;
  }
}

@media only screen and (max-width: 1450px) {
  .hero-skills {
    margin-top: 20px;
  }
  .hero-skills-inner h1 {
    font-size: 22px;
    margin: 0 !important;
  }
  .slider {
    position: relative;
    left: 170px;
  }
}

@media only screen and (max-width: 600px) {
  .about {
    display: flex;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 20%;
  }
  .container {
    display: flex;
    width: 100%;
  }
  .hero {
    width: 100%;
  }
  .hero-content {
    display: flex;
    position: static !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    width: 90% !important;
  }
  .hero-content-line {
    width: 100% !important;
    height: 40px !important;
  }
  .hero-content-inner h1 {
    font-size: 18px !important;
    margin-bottom: -10px !important;
  }
  .hero-inner {
    height: 100% !important;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: flex-end !important;
  }
  .btn-row {
    position: static !important;
    display: flex;
    align-items: center;
  }
  .arrow-icon {
    position: static !important;
    margin-left: 10px;
  }
  .hero-skills {
    width: 90%;
    margin-top: 15%;
  }
  .hero-skills h1 {
    font-size: 18px !important;
    margin-bottom: 25px !important;
  }
  .hero .hero-skills-inner {
    position: static !important;
    width: 90%;
  }
  .hero-skills-inner  {
    position: static !important;
  }
  .hero .hero-icon p {
    width: 40% !important;
  }
  .hero .hero-icon {
    justify-content: space-around;
  }
  .slider {
    display: none !important;
  }
}
