.progress {
  position: absolute;
  top: 30%;
  left: 40%;
}

h1 {
  font-weight: lighter;
}

.progress img {
  margin-left: 50px;
}
